import { CountryType } from "../src/types/airgraft";

/**
 * Country & subcountry (State or Province) configurations
 */
const countries: Record<
  CountryType,
  {
    subcountries: {
      value: string;
      label: string;
      default?: boolean;
      hidden?: boolean;
      productVisibilityField: string; // Which product field to use to check if product is available in this subcountry?
    }[];
  }
> = {
  US: {
    subcountries: [
      {
        value: "CA",
        label: "California",
        default: true,
        productVisibilityField: "availableUs"
      },
      {
        value: "OH",
        label: "Ohio",
        productVisibilityField: "availableUsOh"
      },
      {
        value: "MO",
        label: "Missouri",
        productVisibilityField: "availableUsMo"
      },
      {
        value: "UT",
        label: "Utah",
        hidden: true,
        productVisibilityField: "availableUsUt"
      },
      {
        value: "WA",
        label: "Washington",
        productVisibilityField: "availableUsWa"
      },
      {
        value: "CO",
        label: "Colorado",
        productVisibilityField: "availableUsCo"
      }
    ]
  },
  CA: {
    subcountries: [
      {
        value: "CA_ALL",
        label: "All Provinces (Medical)",
        default: true,
        productVisibilityField: "availableCaAll"
      },
      {
        value: "ON",
        label: "Ontario",
        hidden: true,
        productVisibilityField: "availableCa"
      },
      {
        value: "AB",
        label: "Alberta",
        hidden: true,
        productVisibilityField: "availableCaAb"
      }
    ]
  }
};

// NOTE IF ADDING NEW SUBCOUNTRY: Dont forget to add the product visibility field inside framents/products.ts and gatsby-node.ts

export default countries;

import { Hidden } from "@material-ui/core";
import { graphql, useStaticQuery } from "gatsby";
import React, { useEffect, useState } from "react";
import ExpressDeliveryAvailabilityButton from "./ExpressDeliveryAvailabilityButton";
import CartButton from "./CartButton";
import { getSubcountryLabel } from "../../helpers/country";
import { isPageDisabledByFeatureFlags } from "../../helpers/feature-flags";
import { SHOP_DISCOVER_PATH, SHOP_PRODUCTS_PATH } from "../../helpers/url";
import useCountry from "../../hooks/use-country";
import useFeatureFlag from "../../hooks/use-feature-flag";
import useIsAuthenticated from "../../hooks/use-is-authenticated";
import useLocale from "../../hooks/use-locale";
import useSubcountry from "../../hooks/use-subcountry";
import useTranslate from "../../hooks/use-translate";
import useUnlocalizedLocationPath from "../../hooks/use-unlocalized-location-path";
import ShopingCartIcon from "../../components/icons/shopping-cart";
import LocalizedLink from "../../components/localized-link";
import BaseHeader from "./BaseHeader";
import DesktopCountryLanguageMenu from "./Desktop/DesktopCountryLanguageMenu";
import DesktopDropDownMenu from "./Desktop/DesktopNavigationMenu";
import { ShopButton } from "./Desktop/DesktopNavigationMenu/styles";
import MobileNavigationMenu from "./Mobile/MobileNavigationMenu";
import StoreLocatorAddressButton from "./StoreLocatorAddressButton";
import NotificationButton from "./NotificationButton";

export type HeaderVariantType = "default" | "overlaps";

const Header = ({
  variant,
  disableNavigation = false
}: {
  variant: HeaderVariantType;
  disableNavigation?: boolean;
}) => {
  const t = useTranslate();
  const locale = useLocale();
  if (!locale) return null;
  const country = useCountry();
  const { subcountry } = useSubcountry();

  const isXpressEnabled = useFeatureFlag("SHOP_XPRESS");
  const isShopDiscoverEnabled = useFeatureFlag("SHOP_DISCOVER");
  const isShopPLPEnabled = useFeatureFlag("SHOP_PLP");
  const isUnlockAppLinksEnabled = useFeatureFlag("UNLOCK_APP_LINKS");
  const isNotificationsEnabled = useFeatureFlag("HEADER_NOTIFICATIONS");

  const pathname = useUnlocalizedLocationPath();
  const isAuthenticated = useIsAuthenticated();

  // Header navigation items from DatoCMS
  const {
    datoCmsGeneralHeader: { _allNavigationLocales: localizedNavigationItems }
  } = useStaticQuery<Queries.HeaderFragmentQuery>(query);

  let filteredNavigationItems =
    localizedNavigationItems.find(item => item.locale === locale)?.value || [];

  // Hide navigations items link disabled by feature flags
  filteredNavigationItems = filteredNavigationItems.filter(item => {
    return !isPageDisabledByFeatureFlags(country, (item.target as any)?.path, {
      subcountry
    });
  });

  // Hide "Unlock pod" menu link if Unlock pod links feature flag is disabled
  if (!isUnlockAppLinksEnabled) {
    filteredNavigationItems = filteredNavigationItems.filter(
      item => (item.target as any)?.path !== "unlock"
    );
  }

  // Apply authentication visibility filters (authenticated/unauthenticated)
  const [authVisibleFilter, setAuthVisibleFilter] = useState(["any"]);
  useEffect(() => {
    if (isAuthenticated) {
      setAuthVisibleFilter(["authenticated", "any"]);
    } else {
      setAuthVisibleFilter(["unauthenticated", "any"]);
    }
  }, [isAuthenticated]);

  filteredNavigationItems = filteredNavigationItems.filter(item => {
    if (item.authenticationVisibility) {
      return authVisibleFilter.includes(item.authenticationVisibility);
    }
    return true;
  });

  // Apply subcountry visibility filters
  filteredNavigationItems = filteredNavigationItems.filter(item => {
    if (subcountry && item.subcountryVisibility.length > 0) {
      const currentSubcountryLabel = getSubcountryLabel(subcountry);
      return item.subcountryVisibility.find(
        item => item.name === currentSubcountryLabel
      );
    }
    return true;
  });

  // Apply platform visibility filters (mobile/desktop)
  const desktopNavigationItems = filteredNavigationItems.filter(
    item => item.visibility !== "mobile only"
  );
  const mobileNavigationItems = filteredNavigationItems.filter(
    item => item.visibility !== "desktop only"
  );

  // Header XPRESS subaddress bar visibility logic
  // Only show if:
  // 1. Inside Shop
  const isHomepage = pathname === "/";
  const isShop = pathname.startsWith("/shop");

  const isExpressAddressButtonVisible = isXpressEnabled && isShop;

  // Header "Browse products" button visibility logic
  const isBrowseShopButtonVisible =
    (isShopDiscoverEnabled || isShopPLPEnabled) && isHomepage;
  const browseShopButtonPath = isShopDiscoverEnabled
    ? SHOP_DISCOVER_PATH
    : SHOP_PRODUCTS_PATH;

  // Header "Store locator address" visibility logic
  const isStoreLocator = pathname.startsWith("/store-locator");
  const isStoreLocatorAddressButtonVisible = isStoreLocator;

  // Show logo only if header is disabled
  if (disableNavigation) {
    return <BaseHeader variant={variant} />;
  }

  // Show "Request sample kit" on /partners page
  const isPartnersRequestKitButtonVisible = pathname.startsWith("/partners");

  return (
    <>
      {/* Desktop */}
      <Hidden smDown implementation="css">
        <BaseHeader
          variant={variant}
          leftChildren={
            <>
              <CartButton withPanel hidden={!isExpressAddressButtonVisible} />
              <ExpressDeliveryAvailabilityButton
                withPanel
                hidden={!isExpressAddressButtonVisible}
              />
              {isStoreLocatorAddressButtonVisible && (
                <StoreLocatorAddressButton withPanel />
              )}
              {isBrowseShopButtonVisible && (
                <LocalizedLink to={browseShopButtonPath}>
                  <ShopButton startIcon={<ShopingCartIcon />}>
                    {t("browseProducts")}
                  </ShopButton>
                </LocalizedLink>
              )}
              {isPartnersRequestKitButtonVisible && (
                <LocalizedLink
                  to={"http://partners.airgraft.com/"}
                  target="_blank"
                >
                  <ShopButton>Already a partner?</ShopButton>
                </LocalizedLink>
              )}
            </>
          }
          rightChildren={
            <>
              <DesktopCountryLanguageMenu />
              {isNotificationsEnabled && <NotificationButton withPanel />}
              <DesktopDropDownMenu navigationItems={desktopNavigationItems} />
            </>
          }
        />
      </Hidden>

      {/* Mobile */}
      <Hidden mdUp implementation="css">
        <BaseHeader
          variant={variant}
          rightChildren={
            <>
              <CartButton hidden={!isExpressAddressButtonVisible} />
              <NotificationButton />
              <MobileNavigationMenu navigationItems={mobileNavigationItems} />
            </>
          }
          subHeader={
            isExpressAddressButtonVisible ||
            isStoreLocatorAddressButtonVisible ? (
              <>
                {isExpressAddressButtonVisible ? (
                  <ExpressDeliveryAvailabilityButton />
                ) : null}
                {isStoreLocatorAddressButtonVisible ? (
                  <StoreLocatorAddressButton />
                ) : null}
              </>
            ) : null
          }
        />
      </Hidden>
    </>
  );
};

const query = graphql`
  query HeaderFragment {
    datoCmsGeneralHeader {
      _allNavigationLocales {
        locale
        value {
          originalId
          title
          visibility
          authenticationVisibility
          subcountryVisibility {
            name
          }
          target {
            ... on DatoCmsWebPage {
              path
              model {
                apiKey
              }
            }
            ... on DatoCmsProduct {
              slug
              productType
              model {
                apiKey
              }
            }
          }
        }
      }
    }
  }
`;

export default React.memo(Header);
